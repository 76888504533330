@import '../../../styles/customMediaQueries.css';

/* Desktop */
.root {
  /* Size */
  width: 100%;

  /* Layout for child components */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  /* shadows */
  box-shadow: var(--boxShadowLight);
}

.rootLight {
  composes: root;
  /* fill */
  background-color: var(--colorWhite);
}

.rootDark {
  composes: root;
  /* fill */
  background-color: var(--marketplaceColor);
}

.navbarRowLight {
  composes: rootLight;
  height: var(--topbarHeightDesktopSmall);
  flex-direction: row;
  padding-left: 15px;
  padding-right: 15px; 
  @media (--viewportLargeWithPaddings) {
    padding-left: 80px;
    padding-right: 80px;
  }  
}

.navbarRowLight:nth-child(2) {
  border-top: 2px solid var(--marketplaceSecondaryColor);
  height: 50px;
}

.displayFlex {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 60px;
  padding-left: 0px;

  @media (--viewportLarge) {
    padding-left: 36px;
  }
@media (--viewportMd){
  svg{
    margin-right: 10px;
  }
}
  
}

.navbarRowDark {
  composes: rootDark;
  flex-direction: row;
  height: var(--topbarHeightDesktop);

  @media (--viewportLarge) {
    padding-left: 80px;
    padding-right: 80px;
  }
  @media (--viewportSmall){
    padding-left: 15px;
    padding-right: 15px;
  }
@media (--viewportMd){
  --topbarHeightDesktop: 113px;
}
  
}

.rightSideNavbar {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 24px;
}

.hideMenuRightSideNavbar {
  width: 25%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;

  @media (min-width: 1024px) {
    padding: 0 36px;
  }
}

.profileContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 14px;
}

.avatarName {
  font-size: 16px;
  line-height: 18px;
  color: var(--marketplaceColor);
  padding: 0;
  margin: 0;
}

.accountType {
  font-size: 13px;
  line-height: 16px;
  font-weight: 600;
  color: var(--marketplaceSecondaryColor);
  padding: 0;
  margin: 0;
}

/* logo */
.logoLink {
  height: 70px;
  /* border-bottom: 0px solid; */
  transition: var(--transitionStyleButton);
}

.logoLink:hover {
  /* border-bottom: 4px solid var(--marketplaceColor); */
  border-radius: 0;
}

/* Search */
/* .searchLink {
  flex-grow: 1;
  height: 100%;
  margin-right: 24px;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: var(--colorGrey100);

  @media (--viewportLarge) {
    padding-left: 0;
  }
}

.search {
  font-weight: var(--fontWeightMedium);
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0;
  color: var(--colorGrey700);

  display: inline-block;
  margin: 28px 0;
  text-decoration: inherit;

  @media (--viewportMedium) {
    font-weight: var(--fontWeightMedium);
  }
} */

.topbarSearch {
  padding-left: 24px;
  /* height: var(--topbarHeightDesktop); */
  height: 40px;
  background-color: var(--colorGrey100);

  @media (--viewportLarge) {
    /* padding-left: 34px; */
  }
  @media (--viewportMd) {
    padding-left: 0;
  }
}

/* Create listing (CTA for providers) */
/* .createListingLink {
  border-bottom: 0px solid;
  transition: var(--transitionStyleButton);

  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
  color: var(--marketplaceColor);

  flex-shrink: 0;
  height: 100%;
  padding: 0 12px 0 12px;
  margin: 0;

  &:hover {
    color: var(--marketplaceColorDark);
    border-bottom: 4px solid var(--marketplaceColor);
    border-radius: 0;
    text-decoration: none;
  }

  @media (--viewportMedium) {
    line-height: 16px;
    margin: 0;
  }
} */

.topbarDesktopLabel {
  composes: textSmall from global;
  display: inline-block;
  margin: 28px 0;
  text-decoration: inherit;
}

.createListing {
  composes: topbarDesktopLabel;
}

/* Inbox */
/* .inboxLink {
  border-bottom: 0px solid;
  transition: var(--transitionStyleButton);

  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
  color: var(--colorGrey700);

  height: 100%;
  padding: 0 12px 0 12px;
  margin: 0;

  &:hover {
    color: var(--colorBlack);
    border-bottom: 4px solid var(--marketplaceColor);
    border-radius: 0;
    text-decoration: none;
  }

  @media (--viewportMedium) {
    line-height: 16px;
    margin: 0;
  }
} */

/* .inbox {
  composes: topbarDesktopLabel;
  position: relative;
} */

.notificationDot {
  /* Dimensions */
  width: 7px;
  height: 7px;
  border-radius: 50%;

  /* Position */
  position: absolute;
  top: 2px;
  right: -9px;

  /* Style: red dot */
  background-color: var(--colorFail);

  animation-name: notificationPop;
  animation-duration: 0.1s;
  animation-delay: 0.1s;
  animation-fill-mode: forwards;

  animation-timing-function: ease-in;
  opacity: 0;
  transform: scale(0);
}

@keyframes notificationPop {
  0% {
    transform: scale(0);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

/* Profile menu */
/* .profileMenuLabel {
  border-bottom: 0px solid;
  transition: var(--transitionStyleButton);

  flex-shrink: 0;
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0;
  padding: 0 12px 0 12px;

  &:hover {
    border-bottom: 4px solid var(--marketplaceColor);
    border-radius: 0;
    text-decoration: none;
  }

  &:active {
    border-bottom: 0;
  }
}

.profileMenuIsOpen {
  &:hover {
    border-bottom: 0;
  }
}

*/

.avatar {
  /* margin: 16px 0; */
  /* display: block; */
  border: 2px solid var(--marketplaceAlternativeColor);
}

.profileMenuContent {
  min-width: 276px;
  padding: 20px 30px;
  border-radius: 20px;
}

.profileMenuAvatarContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 25px;
  border-bottom: 1px solid var(--colorBlack);
}

.profileMenuAvatar {
  min-height: 150px;
  min-width: 150px;
}

.profileMenuName {
  font-size: 24px;
  font-weight: 700;
  color: var(--colorBlack);
  padding: 10px 0;
}

.link {
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 0px solid;
  /* transition: var(--transitionStyleButton); */
  flex-shrink: 0;
  height: 40px;
  /* padding: 0 12px 0 12px; */

  color: white;

  &:hover {
    color: #cc9b0a;
    text-decoration: none;
  }
}

.linkCurrent {
  composes: link;
  color: #cc9b0a;
}

.lightLink {
  composes: link;
  color: var(--marketplaceSecondaryColor);

  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }
}

.darkLink {
  composes: link;
  color: var(--marketplaceColor);

  &:hover {
    color: var(--marketplaceSecondaryColor);
    text-decoration: none;
  }
}

.buttonsWrapper {
  display: flex;
  flex-direction: row;
  height: 40px;
  /* background-color: red; */
}

.linkText {
  composes: textSmall from global;

  display: inline-block;
  margin: 8px 0;
  padding: 0 18px;
  text-decoration: inherit;
  @media (--viewportMd){
    padding: 0;
  }
}

.signup,
.login {
  composes: textSmall from global;

  display: inline-block;
  margin: 12px 0;
  font-size: 16px;
  font-weight: 500;
}

/* Authentication */
.signupLink {
  transition: var(--transitionStyleButton);
  background-color: #cc9b0a;
  color: white;
  padding-left: 50px;
  padding-right: 50px;
  font-weight: 500;

  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }

@media (--viewportLg){
  padding-left: 15px;
  padding-right: 15px;
}

}

.loginLink {
  transition: var(--transitionStyleButton);
  background-color: #4885aa;
  color: white;
  padding-left: 50px;
  padding-right: 50px;
  font-weight: 500;

  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }
  
@media (--viewportLg){
  padding-left: 15px;
  padding-right: 15px;
}
}

/* left animated "border" like hover element */
.menuItemBorder {
  position: absolute;
  top: 2px;
  left: 0px;
  height: calc(100% - 4px);
  width: 0;
  transition: width var(--transitionStyleButton);
}

.profileSettingsLink,
.yourListingsLink {
  composes: textSmall from global;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 10px;
  color: var(--marketplaceColor);

  /* Dimensions */
  width: 100%;
  margin: 0;
  padding: 4px 24px;

  /* Layout details */
  /* color: var(--colorGrey700); */
  /* text-align: left; */
  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--colorBlack);
    text-decoration: none;

    & .menuItemBorder {
      width: 6px;
      background-color: var(--marketplaceColor);
    }
  }

  @media (--viewportMedium) {
    margin: 0;
  }
}

.logoutButton {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 10px;
  width: 100%;
  min-width: 276px;
  margin: 0;
  padding: 4px 24px;
  text-decoration: none;
  color: var(--marketplaceColor);

  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;

  transition: var(--transitionStyleButton);
  border: none;

  &:hover {
    cursor: pointer;
    color: var(--colorBlack);
    text-decoration: none;
  }
}

.currentPage {
  color: var(--colorBlack);
  position: relative;

  & .menuItemBorder {
    width: 6px;
    background-color: var(--colorBlack);
  }

  &:hover {
    & .menuItemBorder {
      background-color: var(--colorBlack);
    }
  }
}

.professionalsDropdown {
  position: absolute;
  display: grid;
  min-width: 248px;
  padding: 5px;
  background-color: var(--colorWhite);
  transition: var(--transitionStyleButton);
}

.gigsDropdown {
  composes: professionalsDropdown;
  min-width: 133px;
}

.dropdownLink {
  font-size: 14px;
  width: 100%;
  padding: 5px 10px;

  &:hover {
    text-decoration: none;
    color: var(--colorWhite);
    background-color: var(--marketplaceColor);
  }
}

.dropdownLinkCurrent {
  composes: dropdownLink;
  color: #cc9b0a;
}

.dropdownDisabledLink {
  composes: dropdownLink;
  color: var(--colorGrey400);
}