.root {
  width: 100%;
  border-top: 1px solid var(--marketplaceColor);
  margin-top: 20px;
  margin-bottom: 20px;
}

.rootWhite {
  composes: root;
  border-top: 1px solid var(--colorWhite);
  opacity: 0.3;
}