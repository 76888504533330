@import '../../styles/customMediaQueries.css';

.root {
  composes: h4 from global;
  margin-top: 6px;
  margin-bottom: 0;
  color: var(--colorFail);
  overflow: hidden;
  font-size: 13px;

  @media (--viewportMedium) {
    margin-top: 8px;
  }
}

.icon {
  width: 17px;
  height: 16px;
  margin-right: 4px;
  margin-bottom: 2px;
}