@import '../../styles/customMediaQueries.css';

.root {
  position: relative;
  background-image: url(./images/banner.png);
  background-size: cover;
  background-position: 50% 9%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  min-width: 100%;
  min-height: 390px;
  border-radius: 20px;
}

.root::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 50%; /* Adjust the width as needed */
  background: linear-gradient(
    to right,
    transparent,
    rgba(0, 0, 0, 0.6)
  ); /* Adjust the color and opacity as needed */
  border-radius: 20px; /* Match the border-radius of the .root element */
  z-index: 1;
}

.content {
  display: flex;
  align-self: center;
  flex-direction: column;
  z-index: 2;
}

.title {
  font-size: 20px;
  line-height: 34px;
  font-weight: 700;
  color: var(--colorWhite);
  margin: 0;
}

.buttonLink {
  transition: var(--transitionStyleButton);
  background-color: var(--marketplaceSecondaryColor);
  color: var(--colorWhite);
  margin-top: 20px;
  min-height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: var(--fontWeightSemiBold);
  font-size: 16px;
  line-height: 24px;
  font-family: 'Montserrat';

  &:hover {
    background-color: var(--marketplaceColorDark);
    text-decoration: none;
  }
}

.closeIcon {
  margin: 30px;
  z-index: 2;
  cursor: pointer;
}
