.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    flex: 1 1 0;
}

.label {
    min-height: 32px;
}

.selectInput {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    height: 45px;
    border: 1px solid #4885AA;

    &:hover {
        outline: none;
    }

    &:focus {
        outline: none;
    }
}

.selectedOption {
    font-family: "Open Sans";
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: var(--marketplaceColor);
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    padding: 15px;
}

.arrow {
    margin-right: 14px;
    transition: transform 0.3s ease-in-out;
}

.arrowRotated {
    margin-right: 14px;
    transition: transform 0.3s ease-in-out;
    transform: rotate(180deg);
}

.options {
    position: absolute;
    bottom: 0;
    width: 99%;
    min-height: 100px;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 0px;
    transform: translate(0, 100%);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    z-index: 99999;
    max-height: 300px;
    overflow-y: auto;
}

.option {
    display: flex;
    font-family: "Open Sans";
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 40px;
    cursor: pointer;
    padding-left: 10px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    padding: 10px;
    color: var(--marketplaceColor);
}

.option:hover {
    background-color: var(--marketplaceColor);
    color: white;
}