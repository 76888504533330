.root {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 32px 40px;
    gap: 8px;
    min-width: 344px;
    max-width: 344px;
    border: none;
    border-radius: 20px;
    box-shadow: none;
    background: var(--colorWhite);
}