.root {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #5a7482;
  margin: 0 auto;
}

.prev,
.next {
  margin: 0 15px;
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  line-height: 18px;
  font-family: 'Montserrat';
  font-weight: var(--fontWeightSemiBold);
  color: var(--colorWhite);

  .arrowIcon {
    margin: 0 14px;
    height: 100%;
    /* Color for svg icons */
    fill: var(--colorWhite);
    stroke: var(--colorWhite);
  }

  &:hover {
    .arrowIcon {
      fill: var(--marketplaceColorDark);
      stroke: var(--marketplaceColorDark);
    }
    color: var(--marketplaceColorDark);
  }
}

.prevDisabled,
.nextDisabled {
  margin: 0 15px;
  height: 100%;
  cursor: default;
  display: flex;
  align-items: center;
  line-height: 18px;
  font-family: 'Montserrat';
  font-weight: var(--fontWeightSemiBold);
  color: var(--colorGrey300);

  .arrowIcon {
    margin: 0 14px;
    height: 100%;
  }
}

.disabled,
.disabled:hover {
  fill: var(--colorGrey300);
  stroke: var(--colorGrey300);
}

.pageNumberList {
  display: flex;
  justify-content: space-between;
}

/**
 * Using 'justify-content: space-between;' we can deal with very narrow mobile screens.
 * However, since the length of pageNumberList can vary up to 7,
 * we need to keep track of how much space is allocated for the list
 * Maximum length of pageNumbersNavLinks is 7 (e.g. [1, '…', 4, 5, 6, '…', 9])
 */
.pageNumberList1Items {
  flex-basis: 30px;
}

.pageNumberList2Items {
  flex-basis: 60px;
}

.pageNumberList3Items {
  flex-basis: 100px;
}

.pageNumberList4Items {
  flex-basis: 160px;
}

.pageNumberList5Items {
  flex-basis: 200px;
}

.pageNumberList6Items {
  flex-basis: 250px;
}

.pageNumberList7Items {
  flex-basis: 275px;
}

.toPageLink {
  padding: 7px 14px;
  color: var(--colorWhite);
  font-family: 'Montserrat';
  font-weight: var(--fontWeightSemiBold);

  &:hover {
    text-decoration: none;
    color: var(--marketplaceColorDark);
  }
}

.currentPage {
  padding: 7px 14px;
  color: var(--colorWhite);
  font-family: 'Montserrat';
  font-weight: var(--fontWeightSemiBold);

  border: 2px solid var(--marketplaceSecondaryColor);

  &:hover {
    color: var(--colorWhite);
  }
}

.paginationGap {
  /* Dimensions */
  /* padding: 21px 5px; */
  padding: 7px 14px;

  /* Color for svg icons */
  color: var(--colorGrey700);

  /* Ellipsis is raised from baseline */
  line-height: 13px;
}
