@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
}

.tab {
  margin-left: 16px;

  &:first-child {
    margin-left: 0;
  }

  @media (--viewportLarge) {
    margin-left: 24px;
  }
}

.link {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-self: center;
  white-space: nowrap;
  font-size: 16px;
  font-family: "Open Sans";
  font-weight: 400;
  /* color: var(--colorWhite); */
  margin-right: 10px;
  margin-top: 5px;
  padding-bottom: 5px;
  padding-top: 5px;


  &:hover {
    text-decoration: none;
    color: var(--marketplaceSecondaryColor);
  }
}

.selectedLink {
  color: var(--marketplaceSecondaryColor);
}

.disabled {
  pointer-events: none;
  color: var(--colorGrey300);
  text-decoration: none;
}

.arrow {
  padding-top: 24px;
}