@import '../../styles/customMediaQueries.css';

.root {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.input {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  line-height: 24px;
  border-right: none;
  @media (--viewportSm){
    line-height: 28px;
  }
  &:hover,
  &:focus {
    box-shadow: none;
    border: 1px solid var(--marketplaceAlternativeColor);
    border-right: none;
  }
}

.icon {
  border: 1px solid var(--marketplaceAlternativeColor);
  border-left: none;
  height: 40px;
  background-color: var(--colorWhite);
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  padding-right: 10px;

  display: flex;
  justify-content: center;
  align-items: center;
}
