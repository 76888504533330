@import '../../styles/customMediaQueries.css';

.root {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.container {
  display: flex;
  gap: 20px;
  justify-content: flex-start;
  align-items: center;  
  overflow-x: auto;
  @media (--viewportSm){
    gap: 10px;
  }
}

.selector {
  color: var(--colorWhite);
  border: 1px solid var(--colorWhite);
  border-radius: 20px;
  font-family: 'Montserrat';
  padding: 2px 16px;
  cursor: pointer;
  display: flex;
  gap: 8px;
  transition: var(--transitionStyleButton);
  @media (--viewportSm){
    min-width: fit-content;
  }
  &:hover {
    text-decoration: none;
    background-color: var(--marketplaceSecondaryColor);
    border: 1px solid var(--marketplaceSecondaryColor);
  }
}

.activeSelector {
  composes: selector;
  background-color: var(--marketplaceSecondaryColor);
  border: 1px solid var(--marketplaceSecondaryColor);
}
