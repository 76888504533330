.root {
    /* box-sizing: border-box; */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 32px 40px;
    gap: 8px;
    min-width: 344px;
    max-width: 344px;
    border-radius: 20px;
    box-shadow: none;
    background: linear-gradient(104.36deg, rgba(255, 255, 255, 0.21) 1.09%, rgba(0, 0, 0, 0.117) 50.13%, rgba(255, 255, 255, 0.21) 99.17%);
    border: 1px solid rgba(255, 255, 255, 0.3);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    @media (max-width: 1199px){
        min-width: 100%;
        max-width:  100%;
    }
    @media (max-width: 768px){
        min-width: 100%;
        max-width: 100%;
    }
    @media (max-width: 767px){
    padding: 32px;
    }

}