 
.root {
    display: flex;
    flex-direction: column; 
    row-gap: 40px; 
    @media (max-width: 1199px){
    width: 100%;
    }
    @media (max-width: 991px){
    max-width: calc(100% - 30px);
    margin: 0 auto;}
}