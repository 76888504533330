@import '../../styles/customMediaQueries.css';

.root {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border-top-style: solid;
  border-top-width: 1px;
  border-top-color: var(--colorGrey100); */

  width: 100%;
  /* padding: 24px 24px 42px 24px; */
  padding: 0;
  background-color: var(--marketplaceColor);

  @media (--viewportMedium) {
    /* padding: 0 24px 33px 24px; */
  }

  @media (--viewportLarge) {
    /* padding: 0 0 81px 0; */
    /* border-top: none; */
  }
}

/* Wrapper to limit the top border in desktop
 * view from the outermost paddings*/
.topBorderWrapper {
  @media (--viewportMedium) {
    padding-top: 41px;
  }

  @media (--viewportLarge) {
    padding-top: 73px;

    border-top-style: solid;
    border-top-width: 1px;
    border-top-color: var(--colorGrey100);
  }
}

.content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;

  @media (--viewportMedium) {
    /* margin: 0 auto; */
  }

  @media (--viewportLarge) {
    /* max-width: calc(var(--contentMaxWidth) + 72px); */
    /* padding: 0 100px; */
  }

  @media (--viewportXLarge) {
    /* max-width: var(--contentMaxWidth); */
    /* padding: 0 100px; */
  }
}

/* Mobile only social media links */
.socialLinksMobile {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    display: none;
  }
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  margin-right: 12px;
}

/* Footer links */
.links {
  /* Layout */
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 20px 0;

  /* justify-content: flex-start; */
  /* margin-bottom: 18px; */
  /* gap: 20px; */

  @media (--viewportMedium) {
    flex-wrap: nowrap;
    /* justify-content: space-between; */
    margin-bottom: 0;
  }
}

/* Desktop: logo and organization stuff */
.organization {
  padding: 20px 0;
  /* flex-shrink: 0;
  flex-basis: 220px;
  display: none; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-right: 24px; */
  /* margin-bottom: 24px; */

  @media (--viewportMedium) {
    /* display: block; */
    order: 1;
    /* margin-right: 12px; */
  }

  @media (--viewportLarge) {
    /* flex-basis: 240px;
    margin-right: 57px; */
  }

  @media (--viewportLargeWithPaddings) {
    /* flex-basis: 250px;
    margin-right: 12px; */
  }
}

.logoLink {
  /* display: inline-flex;
  margin-bottom: 14px; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo {
  /* object-fit: contain; */
  /* object-position: left center; */
  width: 100%;
  height: 24px;
}

.organizationInfo {
}

.organizationDescription,
.organizationCopyright {
  composes: marketplaceTinyFontStyles from global;
  color: white;
}

.organizationCopyright {
  margin-top: 24px;
}

/* Desktop and Mobile: links to more information */
.infoLinks {
  order: 2;
  flex-basis: 165px;
  margin-right: 24px;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-right: 12px;
  }
}

.list {
  margin-top: 0;
  margin-bottom: 0;
}

.listItem {
  min-height: 24px;
  margin-bottom: 6px;

  @media (--viewportMedium) {
    margin-bottom: 0;
  }
}

.link {
  /* Font */
  composes: h5 from global;
  line-height: 20px;
  color: var(--colorGrey700);
  transition: var(--transitionStyleButton);

  margin-top: 0;
  margin-bottom: 12px;

  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

/* Desktop and Mobile: searches - first column */
.searches {
  order: 3;
  flex-basis: 165px;
  margin-right: 24px;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-right: 12px;
  }
}

/* Desktop and Mobile: searches - second column */
/* This is not always visible */
.searchesExtra {
  order: 4;
  flex-basis: 165px;
  margin-right: 24px;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-right: 12px;
  }
}

/* Desktop: extra links (social media links and legal links) */
.extraLinks {
  flex-shrink: 0;
  order: 5;
  display: none;
  flex-basis: 109px;
  margin-right: 24px;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-right: 12px;
  }

  @media (--viewportLarge) {
    margin-right: 0;
  }
}

.socialLinks {
  width: 60%;
  display: flex;
  justify-content: flex-end;
}

.legalMatters {
  /* Layout */
  display: flex;
  flex-direction: column;
}

.tosAndPrivacy {
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-bottom: 10px;
  }
}

.copyrightLink,
.legalLink,
.privacy,
.terms {
  composes: marketplaceFooterLinksFont from global;
}

/* Mobile: copyright mark and legal links for mobile */
.copyrightAndTermsMobile {
  /* Positioning */
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  /* Dimensions */
  height: 60px;
  padding: 0 24px 18px 24px;

  /* Layout */
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  background-color: var(--colorWhite);

  @media (--viewportMedium) {
    display: none;
  }
}

.organizationCopyrightMobile {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  color: var(--colorGrey300);
  transition: var(--transitionStyleButton);

  /* Tell parent component how much space this one prefers */
  flex-basis: 172px;

  margin-top: 0;
  margin-bottom: 0;

  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }
}

.tosAndPrivacyMobile {
  flex-basis: 172px;
}

.logoLink,
.copyrightLink,
.privacy,
.terms {
  padding: 0 20px;
  border-left: 1px solid var(--colorWhite);
  text-align: center;
}

.logoLink {
  border: none;
}

.dynamicFooter {
  display: flex;
  justify-content: center;
  gap: 60px;
  padding-top: 40px;
  color: var(--colorWhite); 

  @media (--viewportMd) {
    flex-wrap: wrap; 
    &>div{
      max-width: calc(100%/2 - 40px);
      flex: 100%;
    }
  }
  @media (--viewportSm) {
    flex-wrap: wrap; 
    gap: 0px;  
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
    &>div{
      justify-content: flex-start;
      flex: 0 0 auto;
      width: 100%;
      max-width: 100%;
    }
  }
}

.imageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.image {
  width: 92px;
  height: 92px;

  @media (--viewportMedium) {
    width: 250px;
    height: 250px;
  }
}

.footerHeaders {
  font-size: 26px;
  font-weight: 300;
  @media (--viewportSm) {
    font-size: 20px;
    margin-bottom: 0;
  }
}

.dynamicFooter a {
  color: var(--colorWhite);
  font-size: 14px;
  font-weight: 300;
  text-decoration: none;

  &:hover {
    color: var(--marketplaceSecondaryColor);
  }
}
