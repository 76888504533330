@import '../../styles/customMediaQueries.css';

.root {
  width: 100%;
  position: relative;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  @media (--viewportSm){
    flex-wrap: wrap;
  }
}

.viewProfile {
  font-family: 'Montserrat';
  font-size: 20px;
  font-weight: 600;
  color: var(--marketplaceColor);
  margin-bottom: 30px;
}

.column {
  display: flex;
  justify-items: center;
  flex-direction: column;
  position: relative;
  @media (--viewportSm){
    flex: 100%;
  } 
}

.avatar {
  flex-shrink: 0;
  margin-right: 10px;
}

.name {
  color: var(--marketplaceColor);
  font-family: Open Sans;
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
  padding: 0;
  @media (--viewportSm){
    font-size: 20px;
  }
}

.company {
  color: var(--marketplaceColor);
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 31px;
  letter-spacing: 0.8px;
  margin: 0;
  padding: 0;
  @media (--viewportSm){
    font-size: 18px;
  }
}

.posted {
  color: var(--marketplaceColor);
  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 0;
}

.stars {
  margin: 0 5px;

  &:first-child {
    margin-left: 0;
  }
}

.rating {
  color: var(--marketplaceColor);
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.ratingHighlight {
  composes: rating;
  color: var(--marketplaceAlternativeColor);
  padding-left: 20px;
}

.info {
  font-family: 'Montserrat';
  font-size: 14px;
  color: var(--marketplaceColor);
  padding: 10px 20px 10px 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.tags {
  display: flex;
  gap: 10px;
}

.tag {
  color: var(--colorWhite);
  font-family: 'Montserrat';
  font-weight: 400;
  padding: 4px 15px;
  letter-spacing: 1px;
}

.availableTag {
  composes: tag;
  background-color: var(--marketplaceAlternativeColor);
}

.notAvailableTag {
  composes: tag;
  background-color: var(--colorFail);
}

.topRatedTag {
  composes: tag;
  background-color: var(--marketplaceSecondaryColor);
}

.bidsTag {
  composes: tag;
  background-color: var(--marketplaceColor);
}
.confirmDeletePopup{
  background-color: aqua;
}
.confirmDeletePopupSuccess
{
  background-color: rgb(5, 32, 32);
}


.confirmedIconContainer {
  width: 100%;
  display: grid;
  place-items: center;
}

.modalTitle {
  text-align: center;
  font-family: 'Montserrat';
  font-size: 20px;
  font-weight: 600;
}

.info {
  font-family: 'Montserrat';
  font-weight: 400;
}

.buttons {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  justify-content: flex-end;
}

.cancelButton {
  composes: buttonDefault from global;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.04em;
  border: 3px solid var(--marketplaceColor);
  background-color: var(--colorWhite);
  color: var(--marketplaceColor);

  &:hover {
      color: var(--colorWhite);
      background-color: var(--marketplaceColorDark);
      border: 3px solid var(--marketplaceColorDark);
  }

  &:focus {
      color: var(--colorWhite);
      background-color: var(--marketplaceColorDark);
      border: 3px solid var(--marketplaceColorDark);
  }
}
.disable{
  /* background-color: green; */
  opacity: 0.5;
    pointer-events: none;
}


.ActionPopup{
  position: absolute;
  right: 0;
  top: 56px;
  width: 100%;
  max-width: 198px;
  border-radius: 10px;
  overflow: hidden;
  background: #fff;
  box-shadow: 0 2px 26px #00000021;
  padding: 8px 0;
  .popUpModel{
    padding: 8px 15px;
    display: flex;
    align-content: center;
    cursor: pointer;
    gap: 12px;
    &:hover{
      background-color: #f2f2f2;
    }
  }
}


.customePopUps{
  max-width:100%;
    > div{
      background-color: #274c5f!important;
      > div{
        position: absolute!important;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        margin: 0;
        max-width: 800px;
        margin: 0 !important;
          > button{
            svg{
              fill: black;
            }
          }
          p{
            &.deleteSucessfully{
             span{
              display: block;
              text-align: center;
              width: 100%;
             }
            }
          }
      }
    }
    .buttonMain{
      button{
        &.withBorderBtn{
          background: #fff;
          border: 1px solid #013957;
          color: #013957;
        }
      }
    }
    @media screen and (width < 767px){
      z-index: 99;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        height: 100%;
        width: 100%;
        background-color: #274c5f!important;
        > div{
          > div{
            min-height: unset !important;
             height: auto !important;
             max-width: 90%;
          margin: 0 !important;
          width: 100%;

          }
        }
    }
}