.root {
  grid-template-rows: auto 1fr auto;
  min-height: 100vh;

  @media (max-width: 1199px){
    display: block !important;
  }
}

.topbar {
  position: sticky;
  top: 0;
  z-index: var(--zIndexTopbar);
}

/* .main {
  display: grid;
} */
